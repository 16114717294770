import React from "react";
import {convertStyles} from "../../services/utils"
import {FC} from "preact/compat";
import Link from "next/link";
import {domainUrl} from "../../helper";
import Image from "next/image";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../types/state";
import {setBuilderUrl, setPopup, setPopupName} from "../../store/general";
import defaultBuilderImage from "../../images/defaultpic.webp"

interface IProps {
    item: any;
    dbName?: string
}

const ImageUploadingApp: FC<IProps> = ({item, dbName}) => {
    // console.log(item.images[1], "item.images[1]")
    const dispatch = useDispatch();

    const isSwitchOn = item.switchValue
    const setStyles = convertStyles(item.settings) || {};
    const imageSwitchStyles = !isSwitchOn ? {...setStyles.styles} : {...setStyles.styles, cursor: 'zoom-in'}
    let ImageStyles;
    const fullScreen = item?.settings.find(
        (
            item: { fullScreen: { tabId: number, fullScreen: boolean } }
        ) => item.fullScreen?.fullScreen
    )?.fullScreen;

    if (fullScreen?.tabId === item.tab) {
        ImageStyles = {width: "150%", marginLeft: "-25%"}
    }
    const defaultImage = item.images[0] ? domainUrl(`${dbName}/${item.images[0]?.imageUpload}`) : domainUrl(`${dbName}${defaultBuilderImage.src}`)
    const handleModalOpen = (): void => {
        if (isSwitchOn) {
            dispatch(setPopupName("zoom_in"));
            dispatch(setPopup(true));
            dispatch(setBuilderUrl(defaultImage))
        }
    }
    return (
        <div
            data-aos={setStyles?.animationAos['data-aos']}
            style={{...setStyles.parentStyles} as any}
        >
            {item.hasOwnProperty("linkValue") && item.linkValue.value
                ?
                <Link href={item.linkValue.value}>
                    <a target={item.isLinkChecked ? "_blank" : "_self"}>
                        <div style={imageSwitchStyles as any}>
                            <Image
                                src={defaultImage}
                                alt="builder image"
                                placeholder="blur"
                                layout="fill"
                                priority
                                blurDataURL={`/zega/blur1.webp`}
                                style={imageSwitchStyles as any}
                            />
                        </div>
                    </a>

                </Link>
                :
                <div style={{...imageSwitchStyles, ...ImageStyles} as any}>
                    <Image
                        src={defaultImage}
                        alt="builder image"
                        placeholder="blur"
                        layout="fill"
                        priority
                        blurDataURL={`/zega/blur1.webp`}
                        style={{...imageSwitchStyles, ...ImageStyles} as any}
                        onClick={handleModalOpen}
                    />
                </div>
            }
        </div>
    );
}

export default ImageUploadingApp;
